import React, {FC} from 'react';

import PromoTile from 'web/components/promo_tile';
import assetPath from 'web/helpers/asset_path';

interface GiftCardTileProps {
  onGiftCardView: () => void;
}

const GiftCardTile: FC<GiftCardTileProps> = ({onGiftCardView}) => (
  <PromoTile
    photo={`${assetPath('/img/jujube/gift_cards/giftcard_product_tile.jpg')}?auto=format`}
    productName="Good Eggs Gift Cards"
    vendorName="Good Eggs"
    pricingDetails="Send by email or print at home"
    link="/giftcards"
    onPromoView={onGiftCardView}
  />
);

export default GiftCardTile;
