import React, {FC, PropsWithChildren} from 'react';

export interface PromoTileProps {
  pricingDetails: string;
  vendorName: string;
  link: string;
  onPromoView: () => void;
  productName: string;
  photo: string;
}

const PromoTile: FC<PropsWithChildren<PromoTileProps>> = ({
  link,
  photo,
  pricingDetails,
  productName,
  vendorName,
  onPromoView,
}) => {
  return (
    <div className="grid-item">
      <div className="product-tile product-tile--compact product-tile--market product-tile--promo">
        <div className="product-tile__product-visuals">
          <a
            className="js-product-tile__product-photo-wrapper js-product-photo-link js-product-link"
            href={link}
            onClick={onPromoView}
            data-testid="product-title__product-photo-link"
          >
            <img
              data-testid="product-title__product-photo"
              className="product-tile__product-photo"
              src={photo}
            />
          </a>
        </div>

        <div className="product-tile__product-info">
          <div className="product-tile__product-name-and-details">
            <div className="product-tile__producer-name">{vendorName}</div>
            <a
              data-testid="product-title__product-name-link"
              className="js-product-link"
              href={link}
              onClick={onPromoView}
            >
              <h5 className="product-tile__product-name">{productName}</h5>
            </a>
          </div>

          <div className="product-tile__price-info clearfix">
            <div className="product-tile__purchase-price">
              <div className="price usd split">
                <span className="dollars" />
                <span className="cents" />
              </div>
            </div>

            <div className="product-tile__comparison-price">
              <span className="price-per">{pricingDetails}</span>
            </div>

            <div className="product-tile__purchase-unit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoTile;
