import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'web/components/modal';
import SignInFlow from 'web/components/sign_in_flow';

export default function SignInFlowModal(props) {
  return (
    <div className="sign-in-flow__modal-wrapper">
      <Modal onClose={props.onCloseModal}>
        <div className="sign-in-flow__modal">
          <SignInFlow onAuthenticate={props.onAuthenticate} />
        </div>
      </Modal>
    </div>
  );
}

SignInFlowModal.propTypes = {
  onCloseModal: PropTypes.func,
  onAuthenticate: PropTypes.func.isRequired,
};
