import _ from 'lodash';
import {Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import React, {useState} from 'react';

import basketDuck from 'web/helpers/basket_duck';
import {actions as navigationActions} from 'web/helpers/navigation_duck';
import GiftCardTile from 'web/components/gift_card_tile';
import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';
import MarketTile from 'web/components/market_tile';
import SignInFlowModal from 'web/components/sign_in_flow_modal';
import {reducer as signInFlowReducer} from 'web/components/sign_in_flow/duck';
import segmentAnalytics from '@analytics/client';
import {AppDispatch, PageType} from 'web/helpers/redux_client';

import SearchSidebar from './components/sidebar';
import {StoreData} from './controller';

const SearchPage: PageType<StoreData> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signUpAddProductId, setSignUpAddProductId] = useState<string | null>(null);

  const dispatch: AppDispatch = useDispatch();

  const props = useSelector((state: StoreData) => {
    return {
      searchQuery: state.searchQuery,
      sortedSubcategories: state.sortedSubcategories,
      results: state.results,
      isGiftCardSearch: state.isGiftCardSearch,
      products: state.products,
      indexName: state.indexName,
      queryID: state.queryID,
      basketItemsByProductId: state.basketItemsByProductId,
      settings: state.settings,
      producePath: state.producePath,
      currentFulfillmentDay: state.currentFulfillmentDay,
      fulfillmentDaySummaries: state.fulfillmentDaySummaries,
      fulfillmentDaysByDay: state.fulfillmentDaysByDay,
      favorites: state.favorites,
      user: state.user,
    };
  });

  const reload = (): void => {
    dispatch(navigationActions.reload());
  };

  const handleAuthenticate = (): void => {
    const position = signUpAddProductId ? getProductPosition(signUpAddProductId) : -1;
    const context = getMetricsContext();

    dispatch(
      basketDuck.actions.incrementQuantity({productId: signUpAddProductId, position}, {context}),
    );

    reload();
  };

  const getMetricsContext = (): {[key: string]: unknown} => {
    return {
      feature: 'search',
      searchQuery: props.searchQuery,
      resultCount: getResultCount(),
    };
  };

  const getProductPosition = (productId: string): number => {
    return props.sortedSubcategories.flatMap((subcat) => subcat.productIds).indexOf(productId) + 1;
  };

  const getResultCount = (): number => {
    let resultCount = props.results.length ?? 0;
    if (props.isGiftCardSearch) {
      resultCount += 1;
    }
    return resultCount;
  };

  const trackSearchHit = (nextAction: string, productId?: string): void => {
    const metricProperties = {
      searchQuery: props.searchQuery,
      resultCount: getResultCount(),
    };

    if (productId) {
      const product = props.products[productId];
      const selectedProductName = product.name;
      const selectedProductVendor = product.vendor.name;
      let positionOnPage = props.results.indexOf(productId);
      if (props.isGiftCardSearch) {
        positionOnPage += 1;
      }
      _.assignIn(metricProperties, {
        selectedProductId: productId,
        selectedProductName,
        selectedProductVendor,
        positionOnPage,
        nextAction,
      });
    }

    window.metrics.track('Hit Search', metricProperties);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
  };

  const handleQuickAdd = (productId: string): void => {
    const {user} = props;
    trackSearchHit('quick add', productId);

    if (!user) {
      setIsModalOpen(true);
      setSignUpAddProductId(productId);
    }
  };

  const handleQuickRemove = (productId: string): void => {
    trackSearchHit('quick remove', productId);
  };

  const handleVendorView = (): void => {
    trackSearchHit('vendor details page');
  };

  const handleProductView = (
    productId: string,
    positionIndex: number,
    callback?: () => void,
  ): void => {
    segmentAnalytics.track(
      'searchResultSelected',
      {
        position: positionIndex,
        index: props.indexName,
        objectID: productId,
        query: props.searchQuery,
        queryID: props.queryID,
      },
      () => {
        trackSearchHit('product details page');
      },
    );
    callback?.();
  };

  const handleGiftCardView = (): void => {
    trackSearchHit('gift card page');
  };

  const resultCount = getResultCount();
  const quantityByProductId = props.basketItemsByProductId;
  const title =
    props.searchQuery.length > 0
      ? `Search for '${props.searchQuery}' | Good Eggs`
      : 'Search | Good Eggs';

  return (
    <MarketLayout>
      <Helmet>
        <title>{title}</title>
        <meta name="google-signin-client_id" content={props.settings.googleAuthenticateClientId} />
        <meta
          name="description"
          content="Search for grocery staples, seasonal produce, and local meat and fish for free home delivery."
        />
      </Helmet>

      <div
        className={classNames('content', 'product-listings-view', 'with-sidebar', 'search-page')}
        data-attribution-feature="market"
        data-testid="search-container"
      >
        {isModalOpen ? (
          <SignInFlowModal onCloseModal={handleCloseModal} onAuthenticate={handleAuthenticate} />
        ) : null}

        <div data-attribution-feature="search">
          {!props.searchQuery && (
            <div className="search-no-results search-no-terms">
              <header>Looking to browse the market?</header>
              <div>
                <a className="link" href={props.producePath}>
                  Head back to the marketplace.
                </a>
              </div>
            </div>
          )}

          {resultCount === 0 && (
            <div className="search-no-results">
              <header>We couldn{"'"}t find anything that matches your request.</header>
              <div>
                <span>Search again or </span>
                <a className="link" href={props.producePath}>
                  head to the marketplace
                </a>
                .
              </div>
            </div>
          )}

          <div className="search-page__sidebar-container">
            <SearchSidebar
              sortedSubcategories={props.sortedSubcategories}
              isGiftCardSearch={props.isGiftCardSearch}
            />
          </div>

          <div className="search-page__results-container">
            {props.isGiftCardSearch && (
              <div>
                <div
                  className="search-page__results-subcategory-title"
                  id="search-page__results-subcategory-gift-cards"
                >
                  Gift Card
                </div>
                <GiftCardTile onGiftCardView={handleGiftCardView} />
              </div>
            )}

            {props.sortedSubcategories.map((subcategory) => (
              <div key={`subcategory-${subcategory.subcategory.id}`}>
                <div
                  className="search-page__results-subcategory-title"
                  id={`search-page__results-subcategory-${subcategory.subcategory.id}`}
                >
                  {subcategory.subcategory.name}
                </div>

                <div className="section-page">
                  {subcategory.productIds.map((productId) => {
                    const product = props.products?.[productId];
                    const quantity = quantityByProductId?.[product.id]?.quantity ?? 0;
                    const position = getProductPosition(product.id);

                    return (
                      <MarketTile
                        key={`subcategory-${productId}`}
                        currentFulfillmentDay={props.currentFulfillmentDay}
                        fulfillmentDaySummaries={props.fulfillmentDaySummaries}
                        fulfillmentDaysByDay={props.fulfillmentDaysByDay}
                        product={product}
                        position={position}
                        segmentFeature="search"
                        quantityInBasket={quantity}
                        isFavorited={props.favorites.includes(product.id)}
                        onProductView={(callback) =>
                          handleProductView(productId, position, callback)
                        }
                        onVendorView={handleVendorView}
                        onQuickAdd={handleQuickAdd.bind(this)}
                        context={_(getMetricsContext()).assignIn({subcategory})}
                        onQuickRemove={handleQuickRemove.bind(this)}
                      />
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </MarketLayout>
  );
};

SearchPage.reducer = (state, action) => {
  // TODO: (@shermam) This is not ideal, but the state should always be set here
  // since we preload it from the controller. We should maybe come up with a better
  // way of asserting the type here
  if (!state) throw new Error('State should always be preloaded here');

  let newState = marketLayoutReducer(state, action);
  newState = signInFlowReducer(newState, action);
  return newState;
};

SearchPage.pageName = 'Search';

export default SearchPage;
