/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import {partial} from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class SearchSidebar extends Component {
  onSubcategoryClick(subcategoryId, e) {
    e.preventDefault();
    const headerEl = `search-page__results-subcategory-${subcategoryId}`;
    let offsetTop = __guard__(document.getElementById(headerEl), (x) => x.offsetTop) || 0;

    // Bump the offset to compensate for possible sticky header/add to X header covering the target
    if (offsetTop > 550) {
      offsetTop -= 75;
    }

    window.scroll(0, offsetTop);
  }

  render() {
    return (
      <div className="search-sidebar">
        {this.props.isGiftCardSearch ? (
          <a
            className="search-sidebar__subcategory-link"
            onClick={partial(this.onSubcategoryClick, 'gift-cards')}
          >
            <div className="search-sidebar__subcategory-text-category">Gift Cards</div>
            <div className="search-sidebar__subcategory-text-quantity">(1)</div>
          </a>
        ) : null}

        {this.props.sortedSubcategories.map((subcategory, i) => (
          <a
            key={i}
            className="search-sidebar__subcategory-link"
            onClick={partial(this.onSubcategoryClick, subcategory.subcategory.id)}
          >
            <div className="search-sidebar__subcategory-text">
              <div className="search-sidebar__subcategory-text-category">
                {subcategory.subcategory.name}
              </div>
              <div className="search-sidebar__subcategory-text-quantity">
                ({subcategory.productIds.length})
              </div>
            </div>
          </a>
        ))}
      </div>
    );
  }
}

SearchSidebar.propTypes = {
  sortedSubcategories: PropTypes.array,
  isGiftCardSearch: PropTypes.bool,
};

export default SearchSidebar;

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}
